import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Images
// import jumbotronImage from '../../images/jumbotronBWFlipped.jpg'

// CSS
import './jumbotron.scss'

const JUMBOTRON_QUERY = graphql`
  query jumbotronQuery {
    file(relativePath: { regex: "/Wexler-091-LG-cropped/" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Jumbotron = () => (
  <StaticQuery
    query={JUMBOTRON_QUERY}
    render={data => (
      <div
        className="jumbotron jumbotron-fluid"
        style={{
          // backgroundImage: `url(${jumbotronImage})`,
          backgroundColor: 'white',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          padding: '2rem 0 2rem 0',
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7">
              <Img
                className="jumbotron_LWimg"
                fluid={data.file.childImageSharp.fluid}
              />
            </div>
            <div className="col-xl-5">
              <h2 className="jumbotron_h2">
                From Our Family
                <br />
                to Yours.
              </h2>
              <p className="jumbotron_p">
                Wexler Law Group PLLC is a law firm serving New York City and
                the surrounding area. At Wexler Law Group, Arlene and Leora have
                strong backgrounds in all matters relating to family, from
                zealously advocating our clients’ interests during complex
                matrimonial proceedings, to drafting wills and assisting in real
                estate transactions. As a mother and daughter team, we
                understand the unique dynamics of family, and appreciate the
                need for thoughtful and strategic legal representation in all
                familial matters.
              </p>
              <div className="row">
                <div className="col-sm-6 btn-col">
                  <Link to="/about-us">
                    <button
                      type="button"
                      className="btn btn-primary jumbotron_btn"
                    >
                      ABOUT US &rarr;
                    </button>
                  </Link>
                </div>
                <div className="col-sm-6 btn-col">
                  <Link to="/what-we-do">
                    <button
                      type="button"
                      className="btn btn-primary jumbotron_btn"
                    >
                      WHAT WE DO &rarr;
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Jumbotron
