import React from 'react'

import './index-form.scss'

const IndexForm = () => (
  <div className="IndexForm">
    <div className="container">
      <div className="row text-center">
        <div className="col-lg-12">
          <h2 className="IndexForm_h2">CONTACT US</h2>
        </div>
      </div>
      <div className="row padding-top-bottom">
        <div className="col-lg-3 getInTouchIndex">
          <p>
            <i className="fas fa-home mr-3 IndexForm_contact-info_icon" />
            933 Mamaroneck Ave #204
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Mamaroneck, NY 10543
          </p>
          <p>
            <i className="fas fa-envelope mr-3" />
            ltwexleresq@gmail.com
          </p>
          <p>
            <i className="fas fa-phone mr-3" />
            (914) 835-1600
          </p>
          <p>
            <i className="fas fa-print mr-3" />
            (914) 835-1636
          </p>
        </div>
        <div className="col-lg-9">
          <form
            name="Index-Form"
            method="POST"
            action="/success/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/*JSX-required input*/}
            <input type="hidden" name="form-name" value="Index-Form" />
            {/*Honeypot field*/}
            <input type="hidden" name="bot-field" />
            {/*Real inputs*/}
            <div className="form-group">
              <label htmlFor="FormControlInput1">Name</label>
              <input
                type="text"
                className="form-control"
                name="Name"
                id="FormControlInput1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="FormControlInput2">Email</label>
              <input
                type="email"
                className="form-control"
                name="Email"
                id="FormControlInput2"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="FormControlTextarea1">Message</label>
              <textarea
                className="form-control"
                name="Message"
                id="FormControlTextarea1"
                rows="3"
              />
            </div>
            <button
              type="submit"
              className="btn btn-jumbotron btn-primary btn-index"
            >
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default IndexForm
