import React from 'react'
import { Link } from 'gatsby'

import './index-practice-areas.scss'

import FamilyLaw2 from '../../images/family-law.jpg'
import RealEstateLaw from '../../images/realestatelaw.jpeg'
import TrafficLaw from '../../images/trafficlaw1.jpg'
import ConstructionLaw from '../../images/constructionlaw.jpg'

const IndexPracticeAreas = () => (
  <div className="IndexPracticeAreas">
    <div className="container-fluid">
      <div className="row text-center">
        <div className="col-lg-12">
          <h2 className="IndexPracticeAreas_h2">OUR PRACTICE AREAS</h2>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <Link to="/what-we-do/family-matrimonial-law/">
                <img
                  src={FamilyLaw2}
                  alt="Family Law"
                  className="img-thumbnail IndexPracticeAreas_grayscale"
                />
                <div className="IndexPracticeAreas_centered">FAMILY &rarr;</div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link to="/what-we-do/real-estate-transactions/">
                <img
                  src={RealEstateLaw}
                  alt="Real Estate Law"
                  className="img-thumbnail IndexPracticeAreas_grayscale"
                />
                <div className="IndexPracticeAreas_centered">
                  REAL ESTATE &rarr;
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link to="/what-we-do/traffic-law/">
                <img
                  src={TrafficLaw}
                  alt="Traffic Law"
                  className="img-thumbnail IndexPracticeAreas_grayscale"
                />
                <div className="IndexPracticeAreas_centered">
                  TRAFFIC &rarr;
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link to="/what-we-do/construction-litigation/">
                <img
                  src={ConstructionLaw}
                  alt="Construction Law"
                  className="img-thumbnail IndexPracticeAreas_grayscale"
                />
                <div className="IndexPracticeAreas_centered">
                  CONSTRUCTION &rarr;
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default IndexPracticeAreas
