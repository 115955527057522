import React from 'react'

// Components
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import Jumbotron from '../components/jumbotron/jumbotron'
import IndexAbout from '../components/index/index-about'
import IndexPracticeAreas from '../components/index/index-practice-areas'
import IndexForm from '../components/index/index-form'

// CSS
import './index.scss'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Wexler Law Group PLLC"
      description="Family & Matrimonial Law, Real Estate Transactions, Traffic Law, Construction Litigation"
      keywords={[
        'Family & Matrimonial Law',
        'Real Estate Transactions',
        'Traffic Law',
        'Construction Litigation',
      ]}
    />
    <Jumbotron />
    <IndexAbout />
    <IndexPracticeAreas />
    <IndexForm />
  </Layout>
)

export default IndexPage
