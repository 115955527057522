import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

// CSS
import './index-about.scss'

const imgQuery = graphql`
  query imgQuery {
    file(relativePath: { regex: "/Wexler-091-MD2/" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexAbout = () => (
  <StaticQuery
    query={imgQuery}
    render={data => (
      <div className="IndexAbout">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-10 offset-lg-1 IndexAboutInfo">
              <h2 className="text-center border-bottom-none">
                Family, Real Estate, Traffic & Construction Attorneys
                <br />
                <small className="text-muted text-center">
                  Representing clients throughout Westchester County & NYC
                </small>
              </h2>

              <p className="IndexAboutInfoP">
                Both attorneys find themselves in court almost daily, whether
                advocating for clients in family matters, on traffic violations,
                or in general litigation. Our background in litigation provides
                a competitive edge and a record of zealous representation in
                court and throughout the pendency of proceedings.
                <br />
                <br />
                We are committed to pro bono legal services. Both Arlene and
                Leora serve on the Assigned Counsel Panel for Family Court
                matters, representing indigent persons who cannot otherwise
                afford an attorney. Besides our assigned counsel assistance, the
                Firm acknowledges that with the privilege of practicing law
                comes a responsibility to provide pro bono services to
                individuals who lack financial resources.
                <br />
                <br />
                To learn more about our specific practice areas, please contact
                our office to arrange an initial meeting.
              </p>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default IndexAbout
